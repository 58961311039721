import ReactPlayer from 'react-player';

export default function MothersDay() {
  return (
    <div>
      <ReactPlayer
            className='react-player fixed-bottom'
            url= 'https://emmapeckbucket.s3.amazonaws.com/MOVIE.mp4'
            width='100%'
            height='100%'
            controls = {true}
            />
        <h1>Happy Mother's</h1>
        <h1>Day!</h1>
    </div>
  );
}
