import logo from './logo.svg';
import './App.css';
import MothersDay from './MothersDay';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Landing from './Landing';

function App() {
  return (
    <div className="App">
      <Router>
      <div>
        <nav>
          <ul>

              <Link to="/mothersday2022">Mother's Day 2022</Link>
          </ul>
        </nav>

        <Routes>
        <Route path='/' element={<Landing/>}></Route>
          <Route path="/mothersday2022" element={<MothersDay/>}>

          </Route>
          </Routes>
      </div>
    </Router>
    </div>
  );
}

export default App;
